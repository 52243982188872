@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap");

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,300;9..40,400;9..40,500;9..40,600;9..40,700;9..40,800&display=swap");

:root {
  --background-color: #f1f1f1;
  --secondary-background-color: #ebebeb;
  --primary-color: #001b39;
  --secondary-color: #ff0090;
  --tertiary-color: #ffa500;
  --text-color: #000862;
  --primary-text-color: #111137;
  --white-color: #ffffff;
  --black-color: #000000;
}
body {
  margin: 0;
  font-family: "Poppins";
  background-color: var(--background-color);
  color: var(--text-color);
}

.appbar {
  background: var(--white-color);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 15px;
  z-index: 100;
}

button {
  background-color: var(--primary-color);
  color: var(--white-color);
  padding: 10px 30px;
  cursor: pointer;
  border: 1px solid var(--primary-color);
  display: flex;
  align-items: center;
  font-size: 20px;
  &:hover {
    background-color: transparent;
    color: var(--primary-color);
  }
}

//  font
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
p {
  font-family: "DM Sans";
  font-weight: 600;
}
h1 {
  font-size: 40px;
  font-family: "Montserrat";
}
h2 {
  font-size: 30px;
  font-family: "Montserrat";
}
a {
  text-decoration: none;
  color: inherit;
  font-weight: 600;
  font-size: 19px;
  &:hover {
    color: #0c67bd9b;
  }
}
// text color
.text_1 {
  color: var(--secondary-color);
}
.text_2 {
  color: var(--tertiary-color);
}
.text_3 {
  color: var(--primary-text-color);
}
.text_white {
  color: var(--white-color);
}
.text_black {
  color: var(--black-color);
}

.text_center {
  text-align: center;
}
.App {
  .route-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
  }
  .main_wrapper {
    width: 85%;
    margin: auto;
    max-width: 1300px;
    @media screen and (max-width: 900px) {
      width: 95%;
    }
  }

  .shimmer {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300% 100%;
    -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300%
      100%;
    animation: shimmer 2s infinite;
  }
  @keyframes shimmer {
    100% {
      -webkit-mask-position: left;
    }
  }
}
